<template>
  <section id="Levy">
    <div class="levy">
      <img src="@/assets/icons/Food/levy-logo.png" alt="levy" class="levy-logo" />
      <div class="levy-content">
        <img src="@/assets/images/Food/silverblack.png" alt="silverblack" class="silverblack-logo" />
        <p class="silverblack-text">Exclusively for Allegiant Stadium and crafted in partnership with the Las Vegas Raiders, Levy has launched a unique hospitality brand: Silver &amp; Black Hospitality. This Food and Beverage brand creates a true beacon of world class hospitality, blending tradition with modern, locally driven, hospitality for which Las Vegas is known.</p>
        <div class="levy-points">
          <div class="point">
            A Shared Commitment to Excellence
            <div class="point-line"></div>
          </div>
          <div class="point">
            Value for One, Value for All
            <div class="point-line"></div>
          </div>
          <div class="point">
            Driven to Delight
            <div class="point-line"></div>
          </div>
          <div class="point">
            This is How We Do It
            <div class="point-line"></div>
          </div>
          <div class="point">
            Next Gen Culture
            <div class="point-line"></div>
          </div>
        </div>
        <!-- <div class="levy-content-top">
          <div class="item left">
            <h1>1978</h1>
            <h2>it all began in chicago</h2>
            <p>Brothers Larry and Mark Levy invested in D.B. Kaplan's Delicatessen in Chicago's Water Tower Place. With help from their mother, Eadie, and some authentic family recipes, Levy Restaurants was born.</p>
            <div class="line"></div>
            <div class="dot"></div>
          </div>
          <div class="item center">
            <h1>1989</h1>
            <h2>we made it to disney world</h2>
            <p>Levy became the first outside organization to create, own, and operate restaurants in Walt Disney World Resort.</p>
            <div class="line"></div>
            <div class="dot"></div>
          </div>
          <div class="item right">
            <h1>2020</h1>
            <h2>levy opens allegiant stadium</h2>
            <p>Levy represents the best in versatile menu offerings for corporate and private groups.  With service at the forefront of all conversations and event planning, Levy will impress and delight each guest with their offerings as they welcome private events to Allegiant Stadium.</p>
            <div class="line"></div>
            <div class="dot"></div>
          </div>
        </div>
        <div class="levy-content-middle">
          <div class="middle-line"></div>
        </div>
        <div class="levy-content-bottom">
          <div class="item center">
            <div class="dot"></div>
            <div class="line"></div>
            <h1>1982</h1>
            <h2>sports catering was born</h2>
            <p>Levy pioneered the concept of fine dining in stadiums at the old Comiskey Park (then home of the Chicago White Sox). In 1985, we expanded our services to Wrigley Field, and the rest is history.  To date, Levy now partners with over 164 Sports, Entertainment, and Convention facilities in the US.</p>
          </div>
          <div class="item center second">
            <div class="dot"></div>
            <div class="line"></div>
            <h1>2000</h1>
            <h2>we gained an international partner</h2>
            <p>Levy partnered with Charlotte-based Compass Group North America, the leading food management company in the country, and a subsidiary of UK-based Compass Group PLC, an international industry leader.</p>
          </div>
        </div> -->
      </div>
    </div>
    <LevyMobile class="levy-mobile" />
    <FoodBeverageMobile class="food-mobile" />
  </section>
</template>

<script>
  import LevyMobile from '@/components/sections/FoodBeverage/Levy/LevyMobile'
  import FoodBeverageMobile from '@/components/sections/FoodBeverage/FoodBeverageMobile'

  export default {
    name: "Levy",
    components: {
      LevyMobile,
      FoodBeverageMobile
    }
  }
</script>

<style lang="scss" scoped>
  #Levy {
    min-height: 100vh;
    width: 100vw;
    background-image: url('~@/assets/images/Food/levy-bg-2.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    @media (max-width: 1299px) {
      background-image: none;
    }
    .levy {
      margin-top: 200px;
      margin-left: 351px;
      margin-right: 60px;
      .silverblack-text {
        font-family: 'ArtegraSans-Regular';
        font-size: 16px;
        color: #FFFFFF;
        letter-spacing: 0.8px;
        line-height: 23px;
        text-align: center;
        margin: 50px auto 0 auto;
        width: 75%;
      }
      .levy-points {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 70px;
        .point {
          font-family: 'ArtegraSans-Bold';
          font-size: 18px;
          color: #FFFFFF;
          letter-spacing: 1.2px;
          text-align: center;
          line-height: 20px;
          text-transform: uppercase;
          margin: 0 25px;
          .point-line {
            width: 90%;
            height: 2px;
            margin: 15px auto 0 auto;
            background-color: #fff;
            box-shadow: 0 0 9px 2px #FFFFFF;
          }
        }
      }
      @media (max-width: 1299px) {
        display: none;
      }
      &-logo {
        height: 116px;
        margin: auto;
      }

      &-content {
        display: flex;
        flex-direction: column;
        margin: 50px 0 104px 0;
        &-top {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 21px;
          .left {
            p {
              max-width: 348px;
              margin-right: 20px;
              @media (max-width: 1300px) {
                margin-right: 0;
              }
            }
            .line {
              min-height: 40px;
              flex-grow: 1;
            }
            .dot {
              transform: translate(-50%);
            }
          }
          .center {
            p {
              max-width: 333px;
            }
            .line {
              min-height: 60px;
              flex-grow: 1;
            }
          }
          .right {
            p {
              max-width: 369px;
              margin-left: 20px;
              @media (max-width: 1300px) {
                margin-left: 0;
              }
            }
            .line {
              min-height: 18px;
            }
            .dot {
              transform: translate(50%);
            }
          }
          .line {
            margin-top: 12px;
          }
          .dot {
            bottom: -42px;
          }
        }
        &-middle {
          position: relative;
          height: 23px;
          // min-width: 1050px;
          .middle-line {
            position: absolute;
            top: 9.5px;
            left: 2px;
            height: 2px;
            width: 100%;
            background: #FFFFFF;
            box-shadow: 0 0 9px 2px #FFFFFF;
          }
        }
        &-bottom {
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          margin-top: 22px;
          .line {
            height: 18px;
            margin-bottom: 15px;
          }
          p {
            max-width: 493px;
          }
          .dot {
            top: -44px;
          }
          .second {
            margin-left: 20px;
            @media (max-width: 1300px) {
              margin-left: 0;
            }
          }
        }
        .item {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          width: 50%;
          h1 {
            font-family: 'ArtegraSans-Bold';
            font-size: 18px;
            color: #FFD200;
            letter-spacing: 1.2px;
            line-height: 18px;
          }
          h2 {
            font-family: 'ArtegraSans-Bold';
            font-size: 18px;
            color: #FFFFFF;
            letter-spacing: 1.2px;
            line-height: 31px;
            text-transform: uppercase;
          }
          p {
            font-family: 'ArtegraSans-Regular';
            font-size: 12px;
            color: #FFFFFF;
            letter-spacing: 0.8px;
            line-height: 19px;
          }
        }
        .left {
          align-items: flex-start;
        }
        .center {
          align-items: center;
          p {
            text-align: center;
          }
          h2 {
            text-align: center;
          }
        }
        .right {
          align-items: flex-end;
          text-align: end;
        }
        .line {
          width: 1px;
          background: #FFFFFF;
          box-shadow: 0 0 5px 3px rgba(255,255,255,0.50);
        }
        .dot {
          position: absolute;
          height: 21px;
          width: 21px;
          transform: scaleX(-1);
          background-image: linear-gradient(180deg, #525252 0%, #1F1F1F 100%);
          border: 1px solid #FFFFFF;
          box-shadow: 0 0 5px 3px rgba(255,255,255,0.50);
          border-radius: 10.5px;
          z-index: 2;
        }
      }
      .silverblack-logo {
        width: 380px;
        margin: auto;
      }
    }
    .levy-mobile {
      @media (min-width: 1301px) {
        display: none;
      }
      @media (max-width: $lg) {
        display: none;
      }
    }
    .food-mobile {
      @media (min-width: 993px) {
        display: none;
      }
    }
  }
</style>>
